
  import { mapGetters } from 'vuex'

  export default {
    name: `CheckBasket`,
    props: {
      opened: {
        type: Boolean,
        default: false
     }
    },
    data() {
      return {
        toggleOpen: this.opened,
      }
    },
    computed: {
      ...mapGetters({
        totalCredits: `marketplace/totalCredits`,
        selectedChecks: `marketplace/selectedChecks`
      })
    }
  }
