
  export default {
    name: `MenuDossierReason`,
    data() {
      return {
        toggleTypeOfDossier: undefined,
        toggleJobLocation: undefined,
        toggleReason: undefined,
        jobLocations: undefined,
        typeOfDossiers: undefined,
        reasons: undefined,
      }
    },
    async fetch() {
      const { jobLocations, reasons, dossiers } = await this.$store.dispatch(`marketplace/getReasonsAndDossiers`)
      this.jobLocations = jobLocations
      this.reasons = reasons
      this.typeOfDossiers = dossiers
    },
    methods: {
      async onSelectJobLocation(location) {
        await this.$store.commit(`marketplace/selectedJobLocation`, location)
        this.$emit(`change-job-location`, location)
      },
      async onSelectDossier(dossier) {
        await this.$store.commit(`marketplace/selectedDossier`, dossier)
        this.$emit(`change-dossier`, dossier)
      },
      async onSelectReason(reason) {
        await this.$store.commit(`marketplace/selectedReason`, reason)
        this.$emit(`change-reason`, reason)
      },
      async clearValues() {
        await this.$store.commit(`marketplace/defaultSelectedJobLocation`)
        await this.$store.commit(`marketplace/defaultSelectedReason`)
        await this.$store.commit(`marketplace/defaultSelectedDocument`)
        this.toggleJobLocation = undefined
        this.toggleReason = undefined
        this.toggleTypeOfDossier = undefined
        this.$emit(`cleared`)
      }
    }
  }
