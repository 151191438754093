
  import { mapGetters } from 'vuex'
  import NavigationButtons from '~/components/marketplace/new_check/NavigationsButtons.vue'
  import MenuCategories from '~/components/marketplace/new_check/MenuCategories.vue'
  import MenuDossierReason from '~/components/marketplace/new_check/MenuDossierReason.vue'
  import MenuChecks from '~/components/marketplace/new_check/MenuChecks.vue'
  import CheckBasket from '~/components/marketplace/new_check/CheckBasket.vue'
  import PrivacyPolicy from '~/components/marketplace/new_check/PrivacyPolicy.vue'

  export default {
    name: `StepperStepProduct`,
    components: {
      PrivacyPolicy,
      CheckBasket,
      MenuChecks,
      MenuDossierReason,
      NavigationButtons,
      MenuCategories,
    },
    props: {
      completedSteps: {
        type: [Array, Number],
        default: () => []
      }
    },
    data() {
      return {
        toggleFreeze: false,
      }
    },
    computed: {
      ...mapGetters({
        selectedCategory: `marketplace/selectedCategory`
      }),
      isComplete() {
        return this.completedSteps.includes(1)
      }
    },
    watch: {
      selectedCategory() {
        this.resetObsValidation()
      }
    },
    methods: {
      clearStep() {
        this.clearComponentValues()
        this.resetObsValidation()
        this.$emit(`clear-step`, 1)
      },
      clearComponentValues(){
        this.$refs.menuChecks.clearValues()
        this.$refs.menuCategories.clearValues()
        this.$refs.menuDossierReason.clearValues()
        this.$refs.privacyPolicy.clearValues()
      },
      resetObsValidation() {
        this.$refs.productObs.reset()
      },
      async nextStep() {
        const offset = 70
        const isValid = await this.isObsValid()
        !isValid && this.$vuetify.goTo(this.$refs.productObs, { offset })
        isValid && this.$emit(`next-step`, 2)
      },
      async isObsValid() {
        const isValid = await this.$refs.productObs.validate()
        const selectedChecks = this.$store.getters[`marketplace/selectedChecks`]
        const firstSelectedCheck = selectedChecks.length ? selectedChecks[0] : undefined

        isValid
          && !!firstSelectedCheck
            && await this.$store.dispatch(`marketplace/getCandidateForm`, { check: firstSelectedCheck })

        return isValid
      },
      freeze() {
        this.toggleFreeze = true
      },
      defrost() {
        this.toggleFreeze = false
      }
    }
  }
